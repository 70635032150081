<template>
    <v-container center fluid class="worked-days-overview-container">
        <v-row align="start" justify="center">
            <v-col xs="12" sm="10" md="8" lg="6">
                <v-row align="center" justify="center">
                    <v-col cols="3" align="right">
                        <v-btn
                            class="without-border"
                            dark
                            color="primary"
                            outlined
                            :disabled="isFirstPeriod"
                            @click="goToPreviousPeriod()"
                        >
                            <v-icon dark>mdi-chevron-left</v-icon>
                        </v-btn>
                    </v-col>

                    <v-col cols="6" align="center">
                        <div class="text-header">{{ periodTitle }}</div>
                    </v-col>

                    <v-col cols="3" align="left">
                        <v-btn
                            class="without-border"
                            dark
                            color="primary"
                            outlined
                            @click="goToNextPeriod()"
                        >
                            <v-icon dark>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row align="center" justify="center">
            <v-col>
                <v-data-table
                    id="co-workers-table"
                    class="overview-table"
                    :headers="periodDateArray"
                    :items="userTimeReportInfoArray"
                    :items-per-page="-1"
                    item-key="gid"
                    hide-default-header
                    hide-default-footer
                    fixed-header
                >
                    <template v-slot:header="{ props: { headers } }">
                        <tr>
                            <th>
                                <v-text-field
                                    label="Filtrer"
                                    prepend-inner-icon="mdi-magnify"
                                    clearable
                                    outlined
                                    dense
                                    hide-details
                                    @input="onFilterChange"
                                ></v-text-field>
                            </th>

                            <th v-for="(headerItem, index) in headers" :key="index">
                                {{ getMonthLabel(headerItem) }}
                            </th>

                            <th>TOTAL</th>
                        </tr>
                    </template>

                    <template v-slot:item="{ item }">
                        <coworker-summary
                            v-if="!isLoading"
                            :userTimeReportInfo="item"
                            @show-details="onShowDetails"
                        />
                    </template>

                    <template slot="no-data">
                        <span v-if="!isLoading">Aucune donnée disponible</span>
                        <v-progress-circular
                            v-else
                            indeterminate
                            color="primary"
                            size="50"
                            class="loader"
                        ></v-progress-circular>
                    </template>

                    <template v-slot:footer>
                        <v-container>
                            <v-row justify="center">
                                <v-col offset="2" cols="8">
                                    <v-pagination
                                        v-model="currentPage"
                                        :length="totalPage"
                                        :total-visible="totalVisible"
                                        @input="onPageChange"
                                        class="flex-grow-1"
                                    />
                                </v-col>

                                <v-col cols="2">
                                    <v-select
                                        v-model="selectedPageSize"
                                        :items="pageSizeItems"
                                        @input="onPageSizeChange"
                                        label="Nombre d'éléments par page"
                                        class="align-self-end flex-shrink-1"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment';
import utilsService from '@/services/utils.service';
import dateFormatConstants from '@/constants/date-format.constants';
import utilsConstants from '@/constants/utils.constants';
import CoworkerSummary from '@/components/overview/coworker-summary';

export default {
    name: 'worked-days-overview',

    components: {
        CoworkerSummary,
    },

    props: {
        userTimeReportInfoArray: {
            type: Array,
            required: false,
            default: () => {},
        },
        periodDateArray: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        pageSizeItems: {
            type: Array,
            required: false,
            default: () => [20, 50, 100],
        },
        totalPage: {
            type: Number,
            required: true,
        },
        totalVisible: {
            type: Number,
            required: false,
            default: () => 7,
        },
    },

    data() {
        return {
            currentPage: 1,
            selectedPageSize: 20,
            filter: '',
            timeout: null,
            debounceValue: 500,
        };
    },

    computed: {
        periodTitle() {
            const startDate = utilsService.upperCaseFirstLetter(
                moment(this.periodDateArray[0]).locale('fr').format('MMMM YYYY')
            );
            const endDate = utilsService.upperCaseFirstLetter(
                moment(this.periodDateArray[this.periodDateArray.length - 1])
                    .locale('fr')
                    .format('MMMM YYYY')
            );

            return `Période ${startDate} - ${endDate}`;
        },
        isFirstPeriod() {
            return this.periodDateArray[0] <= utilsConstants.LAUNCH_YEAR_MONTH;
        },
    },

    methods: {
        getMonthLabel(yearMonth) {
            return utilsService.upperCaseFirstLetter(
                moment(yearMonth).locale('fr').format('MMM YYYY')
            );
        },
        goToNextPeriod() {
            const oneYearLaterYearMonth = moment(this.periodDateArray[0])
                .add(1, 'years')
                .format(dateFormatConstants.YEAR_MONTH_FORMAT);

            this.currentPage = 1;

            this.$emit('period-change', oneYearLaterYearMonth);
        },
        goToPreviousPeriod() {
            const oneYearBeforeYearMonth = moment(this.periodDateArray[0])
                .subtract(1, 'years')
                .format(dateFormatConstants.YEAR_MONTH_FORMAT);
            this.currentPage = 1;

            this.$emit('period-change', oneYearBeforeYearMonth);
        },

        onPageSizeChange(selectedPageSize) {
            this.selectedPageSize = selectedPageSize;
            this.currentPage = 1;
            this.$emit('page-size-change', this.selectedPageSize);
        },
        onPageChange(page) {
            this.currentPage = page;
            this.$emit('page-change', this.selectedPageSize, this.currentPage);
        },
        onShowDetails(userInfos) {
            this.$emit('show-details', userInfos);
        },
        onFilterChange(filter) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.currentPage = 1;
                this.$emit('filter-change', filter, this.selectedPageSize, this.currentPage);
            }, this.debounceValue);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.worked-days-overview-container {
    max-width: 1800;
}

.without-border {
    border: none;
}

.pagination-info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.text-header {
    margin: auto;
    padding: 15px;
    background: var(--v-primary-base);
    color: $white;
    text-align: center;
    font-weight: bold;
}

.loader {
    margin: 15px;
}

.overview-table {
    overflow: auto;

    table {
        border: none;

        th {
            background: var(--v-primary-base);
            border-right: solid 1px white;
            border-left: solid 1px white;
            color: white;
            padding: 6px;
            text-align: center !important;

            &:first-child {
                background: white;
                min-width: 180px;
                max-width: 180px;
            }

            &:last-child {
                background: white;
                border: solid 1px var(--v-primary-base);
                color: var(--v-primary-base);
                min-width: 120px;
                max-width: 120px;
            }

            span {
                color: white;
            }
        }

        td {
            .v-chip {
                border: solid 1px var(--v-secondary-base) !important;
                padding: 8px;
            }
        }

        tr {
            &:nth-child(even) {
                background-color: $very-light-gray;
            }
        }
    }
}
</style>
