<template>
    <tr>
        <td class="coworkers-name">
            {{ userTimeReportInfo.name }}
        </td>
        <td
            v-for="timeReportInfo in userTimeReportInfo.timeReportInfoArray"
            :key="timeReportInfo.yearMonth"
        >
            <coworker-summary-item
                :yearMonth="timeReportInfo.yearMonth"
                :monthWorkedDaysNumber="timeReportInfo.monthWorkedDaysNumber"
                :isOutOfContract="timeReportInfo.isOutOfContract"
                :isValidated="timeReportInfo.isValidated"
                :isMonthOverworked="timeReportInfo.isMonthOverworked"
                @show-details="onShowDetails"
            />
        </td>

        <td>
            {{ userTimeReportInfo.totalWorkedDays }} /
            {{
                userTimeReportInfo.flatRateWork >= 0
                    ? `${userTimeReportInfo.flatRateWork}`
                    : 'information manquante'
            }}
        </td>
    </tr>
</template>

<script>
import CoworkerSummaryItem from '@/components/overview/coworker-summary-item';

export default {
    name: 'coworker-summary',

    components: { CoworkerSummaryItem },

    props: {
        userTimeReportInfo: {
            type: Object,
            required: true,
        },
    },

    methods: {
        onShowDetails(yearMonth) {
            this.$emit('show-details', { userGid: this.userTimeReportInfo.gid, yearMonth });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.coworkers-name {
    font-weight: bold;
}

#co-workers-table {
    td {
        border-bottom: none;
        text-align: center;

        &:last-child {
            min-width: 120px;
            max-width: 120px;
        }
    }
    tr:hover:not(.v-table__expanded__content) {
        background: $light-gray;
    }
}
</style>
