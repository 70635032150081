<template>
    <v-btn
        id="worked-day-number"
        class="mx-2"
        :class="getBorderClass()"
        fab
        depressed
        retain-focus-on-click
        :color="getButtonColor()"
        rounded
        x-small
        @click="onClick()"
    >
        {{ monthLabel }}
    </v-btn>
</template>

<script>
import moment from 'moment';
import colors from '@/styles/colors.scss';
import dateFormatConstants from '@/constants/date-format.constants';

export default {
    name: 'coworker-summary-item',

    props: {
        yearMonth: {
            type: String,
            required: true,
        },
        monthWorkedDaysNumber: {
            type: Number,
            required: true,
        },
        isOutOfContract: {
            type: Boolean,
            required: false,
            default: false,
        },
        isValidated: {
            type: Boolean,
            required: false,
            default: false,
        },
        isMonthOverworked: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        monthLabel() {
            if (this.isOutOfContract) {
                return '';
            }
            if (!this.isValidated) {
                return '-';
            }
            return this.monthWorkedDaysNumber;
        },
    },

    methods: {
        onClick() {
            this.$emit('show-details', this.yearMonth);
        },

        getButtonColor() {
            if (this.isOutOfContract) {
                return colors.gray;
            }
            if (!this.isValidated) {
                return colors.white;
            }
            if (this.isMonthOverworked) {
                return colors.darkRed;
            }
            return 'primary';
        },
        getBorderClass() {
            if (this.isOutOfContract) {
                return 'worked-day-number-out-of-contract';
            }
            if (!this.isValidated) {
                if (this.yearMonth < moment().format(dateFormatConstants.YEAR_MONTH_FORMAT)) {
                    return 'not-validated-worked-day-number-past-month';
                }
                return 'not-validated-worked-day-number';
            }
            if (this.isMonthOverworked) {
                return 'overworked-day-number';
            }
            return 'validated-worked-day-number';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

#worked-day-number {
    color: $white;

    &.validated-worked-day-number {
        border: 1px solid var(--v-secondary-base) !important;
    }

    &.not-validated-worked-day-number {
        border: 1px solid $gray !important;
        color: $dark-gray !important;
    }

    &.overworked-day-number {
        border: 1px solid $dark-red !important;
    }

    &.worked-day-number-out-of-contract {
        cursor: not-allowed;
        pointer-events: none;
        border: 1px solid $dark-gray !important;
    }

    &.not-validated-worked-day-number-past-month {
        border: 1px solid $error !important;
        color: $dark-gray !important;
    }
}
</style>
