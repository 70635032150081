<template>
    <div>
        <worked-days-overview
            v-show="isOverviewDisplayed"
            :userTimeReportInfoArray="userTimeReportInfoArray"
            :periodDateArray="periodDateArray"
            :isLoading="isDataLoading"
            :total-page="totalPage"
            @show-details="onShowDetailsFromOverview"
            @period-change="onPeriodChange"
            @page-change="onPageChange"
            @page-size-change="onPageSizeChange"
            @filter-change="onFilterChange"
        />

        <time-report-details
            v-if="!isOverviewDisplayed"
            :readOnly="true"
            :pageSpecificDetails="pageSpecificDetails"
            :receivedYearMonth="yearMonth"
            :userGid="userGid"
            :receivedUserTimeReportInfo="userTimeReportInfo"
            @show-overview="onShowOverview()"
        />
    </div>
</template>

<script>
import moment from 'moment';
import dateFormatConstants from '@/constants/date-format.constants';
import WorkedDaysOverview from '@/components/overview/worked-days-overview';
import TimeReportDetails from '@/components/time-report-details';

export default {
    name: 'time-report-dashboard',

    components: {
        WorkedDaysOverview,
        TimeReportDetails,
    },

    props: {
        isDataLoading: {
            type: Boolean,
            required: true,
        },
        userTimeReportInfoArray: {
            type: Array,
            required: false,
            default: () => {},
        },
        totalPage: {
            type: Number,
            required: true,
        },
        periodDateArray: {
            type: Array,
            required: true,
        },
        pageSpecificDetails: {
            type: Object,
            required: true,
        },
        detailYearMonth: {
            type: String,
            required: false,
            default: undefined,
        },
        detailGid: {
            type: String,
            required: false,
            default: undefined,
        },
    },

    data() {
        return {
            userGid: undefined,
            yearMonth: undefined,
            userTimeReportInfo: undefined,

            isOverviewDisplayed: true,
        };
    },

    created() {
        this.$store.dispatch('monthlySummaryCannotBeHided');
        if (this.detailYearMonth && this.detailGid) {
            this.onShowDetailsFromRoute({
                userGid: this.detailGid,
                yearMonth: this.detailYearMonth,
            });
        } else {
            this.onShowOverview();
        }
    },

    methods: {
        onPeriodChange(yearMonth) {
            this.$emit('period-change', yearMonth);
        },
        onShowOverview() {
            if (this.detailYearMonth && this.detailGid) {
                this.$emit('period-change', moment().format(dateFormatConstants.YEAR_MONTH_FORMAT));
                this.displayOverview();
            } else {
                this.displayOverview();
            }
        },
        onShowDetailsFromOverview({ userGid, yearMonth }) {
            this.userGid = undefined;
            this.yearMonth = yearMonth;
            this.userTimeReportInfo = this.getUserTimereportInfoFromArray(
                this.userTimeReportInfoArray,
                userGid
            );
            this.displayDetails();
        },
        onShowDetailsFromRoute({ userGid, yearMonth }) {
            this.userGid = userGid;
            this.yearMonth = yearMonth;
            this.userTimeReportInfo = undefined;
            this.displayDetails();
        },
        onPageSizeChange(pageSize) {
            this.$emit('page-size-change', pageSize);
        },
        onPageChange(pageSize, page) {
            this.$emit('page-change', pageSize, page);
        },
        onFilterChange(filter, pageSize, page) {
            this.$emit('filter-change', filter, pageSize, page);
        },

        getUserTimereportInfoFromArray(userTimereportInfoArray, userGid) {
            if (userTimereportInfoArray && userTimereportInfoArray.length > 0) {
                return userTimereportInfoArray.find((el) => {
                    return el.gid === userGid;
                });
            }
            return undefined;
        },

        displayOverview() {
            this.isOverviewDisplayed = true;
        },
        displayDetails() {
            this.isOverviewDisplayed = false;
        },
    },
};
</script>
